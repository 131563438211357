import React, { useState, useEffect } from 'react'
import {
  Text,
  Heading,
  Container,
  Stack,
  Divider,
  // ListItem,
  // OrderedList,
  // UnorderedList,
  Button,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import BaseLayout from '../../../layout/base'
import {
  Heading1,
  // Heading2,
  // Heading3,
  // Heading4,
  // Heading5,
  // Heading6,
  Paragraph,
} from '../../../components/ContentWithStyles/TransformToChakra'
import SEO from '../../../components/SEO/PageSEO'

const Page = ({ location, data }) => {
  const [username, setUsername] = useState('Usuario')
  const selectRandomItem = someList =>
    someList[Math.floor(Math.random() * someList.length)]

  const generateRandomName = () => {
    const adj = selectRandomItem(data.wordsJson.english.adjectives)
    const noun = selectRandomItem(data.wordsJson.english.nouns)
    setUsername(
      adj.charAt(0).toUpperCase() +
        adj.slice(1) +
        noun.charAt(0).toUpperCase() +
        noun.slice(1)
    )
  }
  useEffect(() => {
    generateRandomName()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BaseLayout>
      <SEO
        title="Generador de nombres para juegos aleatorios y originales"
        titleSeo="Generador de nombres para juegos aleatorios y originales"
        description="Herramienta para generar nombres para videojuegos al azar que estén disponibles y sean originales: Minecraft, Free Fire, Fortnite, League of Legends, etc."
        image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        breadcrumbs={[
          { name: 'Nombres', url: '/nombres/' },
          { name: 'Juegos', url: '/nombres/juegos/' },
        ]}
        permalink={location.pathname}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Container maxW="5xl">
        <Stack
          textAlign="center"
          align="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            as="span"
          >
            <Text as="span" color="orange.400">
              {username}
            </Text>
          </Heading>
          <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
            Generador de nombres para Instagram originales y chulos
          </Heading>

          <Stack spacing={2} direction="row">
            <Button
              rounded="full"
              px={6}
              colorScheme="orange"
              bg="orange.400"
              _hover={{ bg: 'orange.500' }}
              onClick={() => generateRandomName()}
            >
              Generar
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Divider />
      <Container maxW="5xl" my={10}>
        <Heading1>Title</Heading1>
        <Paragraph>Text</Paragraph>
      </Container>
    </BaseLayout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordsJson {
      english {
        adjectives
        nouns
      }
    }
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
